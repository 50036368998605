@import "../../../node_modules/bootstrap/scss/functions";
@import "./variables-theme";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/code";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "button-themes";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/input-group";
@import "../../../node_modules/bootstrap/scss/custom-forms";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/jumbotron";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/media";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/print";

body {
  position: relative;
  //margin-top: 78px;
}

.navbar {
  //border-bottom: solid 1px rgba(255, 255, 255, .3);
  background-color: transparent !important;

  .navbar-nav {

    @media only screen and (max-width: 680px) {
      background-color: rgba(68, 68, 68, 0.98) !important;
      border-radius: 15px;
    }

    .nav-item {
      font-size: 1.5rem;
      font-weight: 200;
      //color: #444444;

      @media only screen and (max-width: 680px) {
        color: #FFFFFF;
        padding: 15px;
      }
    }
  }

  .navbar-toggler {
    outline: none;
  }
}

#nav-menu {
  &.menu-bg {
    background-color: rgba(255, 255, 255, 0.95) !important;
    -webkit-box-shadow: 0px 6px 14px -2px rgba(0,0,0,0.73);
    box-shadow: 0px 6px 14px -2px rgba(0,0,0,0.73);

    .navbar-toggler {
      background-color: rgba(68, 68, 68, 0.45);
    }

    .navbar-nav {

      @media only screen and (max-width: 680px) {
        background-color: rgba(255, 255, 255, 0.95) !important;
      }

      .nav-item {
        color: #444444;
      }
    }
  }
}

header {
  padding-top: 228px;
  height: 800px;
  //margin-top: 78px;
  background-color: rgba($black, 0.6);
  //border-top: solid 1px rgba(255, 255, 255, .3);

  &::before {
    content: '';
    background-image: url("assets/images/layer-header-bg.jpg");
    background-repeat: no-repeat;
    background-position-y: 0;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 800px;
    z-index: -1;

    @include media-breakpoint-down(sm) {
      height: 300px;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 300px;
    padding-top: 78px;
  }

  h1 {
    font-size: 5rem;
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

}

section {
  padding-bottom: 30px;
}

#s-layer-header {
  margin-top: -330px;

  @include media-breakpoint-down(sm) {
    margin-top: -90px;
  }
}

#s-multiple-sources {
  .layer-r-plotly {
    display: flex;
    color: $white;
    margin-left: 0;
    margin-right: 0;

    .box {
      height: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.box-r {
        background-color: #5E72E4;

        > .logo {
          width: 225px;

          @include media-breakpoint-down(md) {
            width: 175px;
          }
        }
      }

      &.box-plotly {
        background-color: #D46AD1;
         > .logo {
           width: 200px;

           @include media-breakpoint-down(md) {
             width: 150px;
           }
         }
      }

      &.box-chart {
        background-color: #B24E8F;

        > .logo {
          width: 310px;

          @include media-breakpoint-down(md) {
            width: 230px;
          }

          @include media-breakpoint-down(sm) {
            width: 310px;
          }
        }
      }

      .plus-equal {
        position: absolute;
        right: 0;
        margin-right: -60px;
        z-index: 1;

        @include media-breakpoint-down(sm) {
          bottom: 0;
          width: 60px;
          right: auto;
          margin-bottom: -30px;
        }
      }
    }
  }
}

.card-servicos {
  min-height: 500px;
  position: relative;

  .card-thumb {
    position: relative;
    overflow: hidden;
    height: 200px;
  }

  .card-body {
   ul {
     li {
       //white-space: nowrap;
       //overflow: hidden;
       text-overflow: ellipsis;

       display: -webkit-box;
       max-width: 100%;
       -webkit-line-clamp: 4;
       -webkit-box-orient: vertical;
       overflow-y: hidden;
       margin-bottom: 8px;
     }
   }
  }
}
